<template>
    <v-app style="background-color: #f2f2f2">
      <v-layout row wrap align-center justify-center>
        <v-flex lg4 md5 sm7 xs7 class="text-center">
          <img src="@/assets/logo_onebox-horizontal-png.png" width="45%" height="45%" alt="OneBox" />
          <br />
          <br />
          <v-progress-linear
            v-if="fail_ === false"
            background-color="light-blue lighten-4"
            color="success"
            indeterminate
            rounded
          ></v-progress-linear>
          <br />
          <p v-if="fail_ === false" class="text-center">รอสักครู่</p>
          <p v-else class="text-center"><v-icon color="error">error</v-icon>&nbsp;{{errormessage}}</p>
        </v-flex>
      </v-layout>
    </v-app>
  </template>
  <script>
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import "sweetalert2/src/sweetalert2.scss";
  import { mapState, mapGetters } from "vuex";
  import gbfGenerate from "@/globalFunctions/generateAuthorize";
  import VueCookies from "vue-cookies";
  
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });
  
  export default {
    data: function() {
      return {
        fail_: false,
        errormessage: "",
        URL_oneid:"",
      };
    },
    created() {},
    computed: {
      ...mapState(["username", "authorize", "account_active", "color", "role_level"]),
      ...mapState({ processloader: "loading" }),
      ...mapGetters([
        "dataUsername",
        "dataAuthorize",
        "dataAccountActive",
        "dataAccesstoken",
        "dataBusinessProfile",
        "dataCitizenProfile",
        "dataDepartmentAccessId",
        "dataAccountId",
        "dataLoginDefault",
      ]),
    },
    methods: {     
      
    async gotonextpage() {
      console.log("kkkk", this.$route);
      let token = this.$route.query.token
      console.log("token",token);

      // let actual = atob(token)
      let actual = decodeURIComponent(token);
      console.log("เข้าแล้ว");
      console.log("actual",actual);
        // let token = this.$route.query.token.replace(" ","+");
        let payload = {
          token : actual,
        };
        let auth = await gbfGenerate.generateToken();
        console.log("auth", auth);

      this.axios
        .post(
          process.env.VUE_APP_SERVICE_REGISTER + "/api/customer/siampiwat/decrypt",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            console.log("res", response.data.data);
            this.URL_oneid = response.data.data.url
            window.open(this.URL_oneid, "_self");
             // window.open(URL, "_blank");
          } else {
            Toast.fire({
              icon: "error",
              title: "Session expired",
            });
            console.log("errorMessage",response.data.errorMessage);
            this.fail_ = true;
            // this.errormessage = response.data.errorMessage;
            this.errormessage = "Session expired";
          }
        })
        .catch((error) => {
          Toast.fire({
            icon: "error",
            title: error.response.data.errorMessage,
          });
          console.log(error);
        });
      
    },
    },
    mounted() {
      console.log(",kkkkkkkkk", this.$route.query);
      console.log("kkkk", this.$route);
      this.gotonextpage();
  

      
  }
}
  </script>
  